import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import BackgroundImage from "gatsby-background-image"
import heroStyles from "./hero.module.scss"

export default function Hero() {
  const data = useStaticQuery(graphql`
    query HeroImage {
      image: file(relativePath: { eq: "hero-bg.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 3080, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      carbon: file(relativePath: { eq: "100-al-carbon.png" }) {
        childImageSharp {
          fixed(width: 200, height: 200) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `)
  return (
    <BackgroundImage
      className={heroStyles.bg}
      fluid={data.image.childImageSharp.fluid}
    >
      <div id="inicio" className={heroStyles.hero}>
        <Img
          className={heroStyles.carbon}
          fixed={data.carbon.childImageSharp.fixed}
          alt="100% Al Carbón"
          style={{ position: "absolute" }}
        />
      </div>
    </BackgroundImage>
  )
}
