import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import footerStyles from "./footer.module.scss"
import facebookLogo from "../assets/img/face.png"
// import twitterLogo from "../assets/img/tweet.png"
import instagramLogo from "../assets/img/insta.png"

export default function Footer() {
  const data = useStaticQuery(graphql`
    query Footer {
      logo: file(relativePath: { eq: "footer-logo.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <div className={footerStyles.container}>
      <div className={footerStyles.content}>
        <Img
          className={footerStyles.footerLogo}
          fluid={data.logo.childImageSharp.fluid}
          alt="GyrosBBQ Gris"
        />
        <p>Gyros & BBQ. Since 1993 © All rights reserved</p>
        <div className={footerStyles.row}>
          <a
            href="https://es-la.facebook.com/gyrosbbq/"
            rel="noopener noreferrer"
            target="_blank"
          >
            <img src={facebookLogo} alt="Facebook" />
          </a>
          <a
            href="https://www.instagram.com/gyrosbbq/"
            rel="noopener noreferrer"
            target="_blank"
          >
            <img src={instagramLogo} alt="Instagram" />
          </a>
          {/* <a
            href="https://es-la.facebook.com/gyrosbbq/"
            rel="noopener noreferrer"
            target="_blank"
          >
            <img src={twitterLogo} alt="Twitter" />
          </a> */}
        </div>
      </div>
      <p className={footerStyles.credits}>
        Diseñado por{" "}
        <a rel="noopener noreferrer" target="_blank" href="https://zapy.dev">
          Zapy.dev
        </a>
      </p>
    </div>
  )
}
